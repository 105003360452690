
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import utils from '../../util';
import { useLink } from '@c360/ui';

type GoogleAccount = {
  name: string;
  Email: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  PictureUrl: string;
};

export default Vue.extend({
  components: {
    linkedType: () => import('./_linkedType.vue'),
  },
  props: ['shared'],
  data: (): {
    googleAuthMessageNew: string | null;
    googleAuthErrorNew: string | null;
    googleAuthWarning: string | null;
    isAgency: boolean;
    agencyConnections: GoogleAccount | null;
    agencyLoading: boolean;
    googleLoginUrl: string | null;
    headers: Array<object>;
    showIframe: boolean;
    showDeleteConfirmation: boolean;
    showDeleteUnavailable: boolean;
    itemToDelete: GoogleAccount | null;
    loading: {
      delete: boolean;
    };
  } => ({
    googleAuthMessageNew: null,
    googleAuthErrorNew: null,
    googleAuthWarning: null,
    googleLoginUrl: null,
    isAgency: true,
    agencyConnections: null,
    agencyLoading: false,
    showIframe: false,
    headers: [
      {
        text: '',
        sortable: false,
      },
      {
        text: 'Google Analytics',
        sortable: false,
      },
      {
        text: 'Google Ads',
        sortable: false,
      },
      {
        text: 'Google Ad Manager',
        sortable: false,
      },
      {
        text: 'Google Search Console',
        sortable: false,
      },
      {
        text: '',
        sortable: false,
      },
    ],
    showDeleteConfirmation: false,
    showDeleteUnavailable: false,
    itemToDelete: null,
    loading: {
      delete: false,
    },
  }),
  created(): void {
    this.loadAgency();
    this.getGoogleLoginUrl();
    window.addEventListener('message', this.onIframeCommunicationNew);
  },
  mounted(): void {
    const iframe = this.$el.querySelector('#google-agency-login-iframe');
    if (iframe) {
      iframe.src = iframe.src + '&nocache=' + Date.now();
    }
  },
  methods: {
    onIframeCommunicationNew(event): void {
      if (event.data?.action) {
        this.clearMessages();
        switch (event.data.action.toLowerCase()) {
          case 'loadagencynew':
            this.loadAgency();
            if (event.data.message) this.googleAuthMessageNew = event.data.message;
            break;
          case 'onloadednew':
            this.showIframe = true;
            break;
          case 'onexistingaccount':
            if (event.data.message) this.googleAuthWarning = 'This user already exists in current agency.';
            break;
          case 'onerrornew':
            if (event.data.error) this.googleAuthErrorNew = event.data.error;
            break;

          default:
            break;
        }
      }

      return;
    },
    clearMessages(): void {
      this.googleAuthMessageNew = null;
      this.googleAuthErrorNew = null;
      this.googleAuthWarning = null;
    },
    async loadAgency(): Promise<void> {
      try {
        this.clearMessages();
        this.agencyLoading = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resp: AxiosResponse<any> = await this.$store.dispatch('agencyAdmin/getGoogleCredentialConnectionsNew', {
          id: this.agencyId,
          isAgency: true,
          allPossible: true,
        });
        this.agencyConnections = resp;
        this.agencyLoading = false;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('loadAgency error', exp);
        this.agencyLoading = false;
      }
    },
    async getGoogleLoginUrl(): Promise<void> {
      try {
        const tokenResponse = await this.$store.dispatch('agencyAdmin/getGoogleCredentialsLink', { id: this.agencyId });
        if (!tokenResponse || !tokenResponse.token) {
          this.showError = true;
          this.error = 'Sorry, an error occurred creating the share link, please try again.';
          // eslint-disable-next-line no-console
          console.error('getGoogleCredentialsLink error', tokenResponse);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const environment = utils.getEnvironment(window.location.origin, (window as any)._dash_env);

        let urlBase;
        switch (environment) {
          case 'dev':
            urlBase = 'https://agency.analytics.dev.adportal.io';
            break;
          case 'staging':
            urlBase = 'https://agency.analytics.stg.adportal.io';
            break;
          case 'local':
            urlBase = 'https://agency.analytics.dev.adportal.io';
            // urlBase = 'https://localhost:8080';
            // to test the frame page use window.location.origin
            // urlBase = window.location.origin;
            break;
          default:
            urlBase = 'https://agency.analytics.adportal.io';
            break;
        }
        const magicLinkGoogleCreds = `${urlBase}/view/?gctnew=${tokenResponse.token}&site=sbg&agency=${this.agencyId}&framed=t`;
        this.googleLoginUrl = magicLinkGoogleCreds;
      } catch (exp) {
        this.error = 'Sorry, an error occurred creating the share link, please try again.';
        // eslint-disable-next-line no-console
        console.error('getGoogleLoginUrl error', exp.message);
      }
    },
    showDeleteDialog(item: GoogleAccount): void {
      if (this.isAdminHierarchySU) {
        this.itemToDelete = item;
        this.showDeleteConfirmation = true;
      } else {
        this.showDeleteUnavailable = true;
      }
    },
    async remove(googleUserId): Promise<void> {
      try {
        this.loading.delete = true;
        const deleted = await this.$store.dispatch('agencyAdmin/googleCredentialsRemoveTokensNew', {
          agencyId: this.agencyId,
          googleUserId,
        });

        if (deleted) {
          this.loadAgency();
          this.itemToDelete = null;
          this.showDeleteConfirmation = false;
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error(exp);
      } finally {
        this.loading.delete = false;
      }
    },
  },
  computed: {
    agencyId(): string {
      // get the id from state if page is loaded from shared link.
      return this.shared ? this.$store.state.customer.googleCredentialsId : this.$route.params.agency;
    },
    connections(): boolean {
      return this.agencyConnections && this.agencyConnections.length > 0;
    },
    isAdminHierarchySU(): boolean {
      return this.$store.state.customer.user.is_superuser;
    },
    helpAdminLink(): string {
      const agency = utils.getAgencyFromURL();

      const baseURL = useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
      return `${baseURL}/help/request`;
    },
  },
});
